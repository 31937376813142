import React from 'react';
import './SignIn.css';
import SoundButton from '../Components/SoundButton'; 
import SignInField from '../Components/SignInField'; 

const Login = () => {
  return (
    <div className="login-container">
      <SignInField />
    </div>
  );
};

export default Login;
