import React, { useState } from 'react';
import './Searchbar.css'; // Import the CSS file for styling
import { ThemeContext } from '../ThemeContext';

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="input-container">
      <input
        type="text"
        className="input"
        placeholder="Search the page..."
        value={searchTerm}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default SearchBar;
