import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '../../ThemeContext';
import './Keyboard.css';

const englishLayout = [
  ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
  ['Shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm'],
  ['Space']
];

const germanLayout = [
  ['q', 'w', 'e', 'r', 't', 'z', 'u', 'i', 'o', 'p', 'ü'],
  ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä'],
  ['Shift', 'y', 'x', 'c', 'v', 'b', 'n', 'm', 'ß'],
  ['Space']
];

const russianLayout = [
  ['й', 'ц', 'у', 'к', 'е', 'н', 'г', 'ш', 'щ', 'з', 'х', 'ъ'],
  ['ф', 'ы', 'в', 'а', 'п', 'р', 'о', 'л', 'д', 'ж', 'э'],
  ['Shift', 'я', 'ч', 'с', 'м', 'и', 'т', 'ь', 'б', 'ю'],
  ['Space']
];

const layouts = {
  english: englishLayout,
  german: germanLayout,
  russian: russianLayout,
};

const Keyboard = ({ layout, onKeyPress }) => {
  const { theme } = useContext(ThemeContext);
  const [pressedKey, setPressedKey] = useState(null);
  const [isShiftActive, setIsShiftActive] = useState(false);

  const handleKeyPress = (key) => {
    if (key === 'shift') {
      setIsShiftActive(!isShiftActive);
    } else if (key === 'enter') {
      // Trigger form submission without writing "enter"
      onKeyPress(''); // Pass an empty string to avoid adding "enter" to the input
      document.querySelector('form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    } else if (key === 'space') {
      onKeyPress('space');
    } else if (key === 'backspace') {
      onKeyPress('backspace'); // Handle backspace
    } else if (key.length === 1) { // Ensure only single character keys are processed
      const finalKey = isShiftActive ? key.toUpperCase() : key;
      onKeyPress(finalKey); // Pass the correct case to onKeyPress

      // Deactivate shift after a letter is pressed
      if (isShiftActive) {
        setIsShiftActive(false);
      }
    }
    
    setPressedKey(key.toLowerCase());
    setTimeout(() => setPressedKey(null), 100);
  };

  useEffect(() => {
    const handleRealKeyPress = (event) => {
      if (event.defaultPrevented) return;

      if (event.key === 'Shift') {
        setIsShiftActive(!isShiftActive);
        handleKeyPress('shift');
      } else if (event.key === ' ') {
        handleKeyPress('space');
      } else if (event.key === 'Enter') {
        handleKeyPress('enter'); // Handle Enter key
      } else if (event.key === 'Backspace') {
        handleKeyPress('backspace'); // Handle Backspace key
      } else if (event.key.length === 1) { // Ensure only single character keys are processed
        const key = isShiftActive ? event.key.toUpperCase() : event.key.toLowerCase();
        handleKeyPress(key);
      }
      event.preventDefault();
    };

    window.addEventListener('keydown', handleRealKeyPress);
    return () => {
      window.removeEventListener('keydown', handleRealKeyPress);
    };
  }, [isShiftActive]);

  const currentLayout = layouts[layout];

  return (
    <div className={`keyboard ${theme}`}>
      {currentLayout.map((row, rowIndex) => (
        <div key={rowIndex} className="keyboard-row">
          {row.map((key) => (
            <button
              key={key}
              className={`keyboard-key ${key.toLowerCase()} ${pressedKey === key.toLowerCase() ? 'pressed' : ''}`}
              onClick={() => handleKeyPress(key.toLowerCase())}
            >
              {isShiftActive && key !== 'Space' && key !== 'Shift' ? key.toUpperCase() : key}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Keyboard;
