import React, { useState, useEffect } from 'react';
import './InfinyVocab.css'; // Import the CSS file
import Keyboard from '../Components/Infiny/Keyboard'; // Import the Keyboard component
import SendIcon from '@mui/icons-material/Send'; // Import the MUI Send icon
import ReducedNavbar from '../Components/ReducedNavbar'; // Import the ReducedNavbar component

// Import vocabulary sets
import vocabulary from '../data/vocabulary.json';
import nouns100 from '../data/nouns100.json';
import verbs100 from '../data/verbs100.json'; 
import adjectives100 from '../data/adjectives100.json'; 

function InfinyVocab() {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [feedback, setFeedback] = useState('');
  const [languageToLearn, setLanguageToLearn] = useState('german'); // Default to German
  const [keyboardLayout, setKeyboardLayout] = useState('german'); // Keep track of the keyboard layout
  const [nativeLanguage, setNativeLanguage] = useState('english'); // Make native language stateful
  const [vocabularySet, setVocabularySet] = useState('default'); // New state for vocabulary set
  const [score, setScore] = useState(0); // New state for score

  // Determine the current vocabulary based on the selected set
  const currentVocabulary = vocabularySet === 'nouns100' ? nouns100 
                          : vocabularySet === 'verbs100' ? verbs100
                          : vocabularySet === 'adjectives100' ? adjectives100  
                          : vocabulary;
  const currentWord = currentVocabulary[currentWordIndex];
  

  // Check if the answer is correct
  const checkAnswer = () => {
    const correctAnswer = currentWord[languageToLearn];
    if (userInput === correctAnswer) {
      setFeedback('Correct!');
      setScore((prevScore) => prevScore + 10); // Increase score by 10
    } else {
      setFeedback(`The correct answer was "${correctAnswer}".`);
    }
  };

  // Function to get a random word index
  const getRandomWordIndex = () => Math.floor(Math.random() * currentVocabulary.length);

  // Update the current word index to a random one
  const handleSubmit = (event) => {
    event.preventDefault();
    checkAnswer();
    setUserInput(''); // Clear input field
    setCurrentWordIndex(getRandomWordIndex()); // Set to a random index

    // Trigger the button click animation
    const button = event.target.querySelector('button');
    button.classList.add('active');
    setTimeout(() => button.classList.remove('active'), 100); // Remove the class after 100ms
  };

  // Handle input changes
  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleVirtualKeyPress = (key) => {
    if (key === 'backspace') {
      setUserInput((prev) => prev.slice(0, -1)); // Remove the last character
    } else if (key === 'space') {
      setUserInput((prev) => prev + ' ');
    } else {
      setUserInput((prev) => prev + key);
    }
  };
 
  // Automatically update the keyboard layout when the language changes
  useEffect(() => {
    setKeyboardLayout(languageToLearn);
  }, [languageToLearn]);

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <div className="InfinyVocab">
      <ReducedNavbar /> {/* Add the ReducedNavbar component here */}
      

      <h1>Vocabulary Trainer</h1>
      
      <div className="word-display">
        <p>
          <strong>Translate</strong> 
          <select
            value={vocabularySet}
            onChange={(e) => setVocabularySet(e.target.value)}
          >
            <option value="default">Default Set</option>
            <option value="nouns100">Top 100 Nouns</option>
            <option value="verbs100">Top 100 Verbs</option> 
            <option value="adjectives100">Top 100 Adjectives</option> 
          </select>
          <strong>from</strong> 
          <select
            value={nativeLanguage}
            onChange={(e) => setNativeLanguage(e.target.value)}
          >
            <option value="english">English</option>
            <option value="german">German</option>
            <option value="russian">Russian</option>
          </select>
          <strong>to</strong> 
          <select
            value={languageToLearn}
            onChange={(e) => setLanguageToLearn(e.target.value)}
          >
            <option value="german">German</option>
            <option value="english">English</option>
            <option value="russian">Russian</option>
          </select>
          :
        </p>
        <h2>{currentWord[nativeLanguage]}</h2>
      </div>

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          placeholder={`Enter the ${capitalizeFirstLetter(languageToLearn)} word here`}
        />
        <button type="submit">
          <SendIcon className="submit-icon"/> {/* MUI Send icon */}
        </button>
      </form>

      <div className="feedback">
        <p><strong>{feedback}</strong></p>
      </div>

      <div className="score-display">{score}</div> {/* Add score display */}

      {/* Pass the keyboard layout and the function to handle key presses */}
      <Keyboard layout={keyboardLayout} onKeyPress={handleVirtualKeyPress} />
    </div>
  );
}

export default InfinyVocab;
