import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import SignIn from './Pages/SignIn';
import InfinyVocab from './Pages/InfinyVocab';
import { ThemeProvider } from './ThemeContext'; // Import ThemeProvider
import './App.css';

const App = () => {
  return (  
    <ThemeProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/SignIn" element={<SignIn />} />
            <Route path="/vocabulary" element={<InfinyVocab />} />
          </Routes>
        </div>
      </Router>
      </ThemeProvider>
  );
};

export default App;

