import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import './WidgetContainer.css'; // Import the updated CSS file for styling
import VocabularyIcon from '@mui/icons-material/Translate'; // Example icon
import GrammarIcon from '@mui/icons-material/School';
import StoryIcon from '@mui/icons-material/MenuBook';
import BrushIcon from '@mui/icons-material/Brush';
import MediaIcon from '@mui/icons-material/PermMedia';
import { ThemeContext } from '../ThemeContext';

const WidgetContainer = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  return (
    <div className="widget-grid">
      <div
        className="widget widget-2x4"
        id="vocabulary-widget"
        onClick={() => navigate('/vocabulary')} // Add onClick handler
      >
        <VocabularyIcon className="widget-icon" />
        <h3>Vocabulary</h3>
      </div>
      <div
        className="widget widget-2x2"
        id="grammar-widget"
        onClick={() => navigate('/grammar')} // Add onClick handler
      >
        <GrammarIcon className="widget-icon" />
        <h3>Grammar</h3>
      </div>
      <div
        className="widget widget-2x1"
        id="story-widget"
        onClick={() => navigate('/stories')} // Add onClick handler
      >
        <StoryIcon className="widget-icon" />
        <h3>Stories</h3>
      </div>
      <div
        className="widget widget-2x3"
        id="art-widget"
        onClick={() => navigate('/art')} // Add onClick handler
      >
        <BrushIcon className="widget-icon" />
        <h3>Art</h3>
      </div>
      <div
        className="widget widget-2x2"
        id="media-widget"
        onClick={() => navigate('/media')} // Add onClick handler
      >
        <MediaIcon className="widget-icon" />
        <h3>Media</h3>
      </div>
    </div>
  );
};

export default WidgetContainer;
