import React, { useState, useEffect, useRef } from 'react';
import './LanguageSelector.css';


const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const languages = ['English', 'Deutsch', 'Русский'];
  const [menuWidth, setMenuWidth] = useState(0);
  const menuRef = useRef(null);

  useEffect(() => {
    // Automatically adjust width based on the longest language
    if (menuRef.current) {
      const longestLanguage = languages.reduce((a, b) => (a.length > b.length ? a : b));
      const width = menuRef.current.offsetWidth + 20; // Add padding space
      setMenuWidth(width);
    }
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectLanguage = (language) => {
    setSelectedLanguage(language);
    setIsOpen(false); // Collapse the dropdown after selecting
  };

  return (
    <div className="language-selector" style={{ width: `${menuWidth}px` }}>
      <div className="lselected" onClick={toggleDropdown} ref={menuRef}>
        {selectedLanguage}
        <span className={`arrow ${isOpen ? 'down' : 'right'}`}></span>
      </div>
      {isOpen && (
        <ul className="dropdown">
          {languages.map((language, index) => (
            <li
              key={index}
              className="dropdown-item"
              onClick={() => selectLanguage(language)}
            >
              {language}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;
