import React from 'react';
import './Navbar.css'; // Import the CSS file for styling
import Searchbar from './Searchbar'; // Import the Searchbar component
import Toggle from './Toggle'; // Import the Toggle component
import ProfileButton from './ProfileButton'; // Import the ProfileButton component
import LanguageSelector from './LanguageSelector'; // Import the ProfileButton component
import Logo from '../Ressources/ZemtsAcademyText.svg';
import ZemtsButton from './ZemtsButton';
import { ThemeContext } from '../ThemeContext';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-left">
        {/* Add the SVG logo above the Searchbar */}
        <button id="ZemtsAcademy">ZEMTS ACADEMY</button>
        <Searchbar />
      </div>
      <div className="navbar-right">
        {/* Right part of the navbar */}
        <LanguageSelector/>
        <Toggle />
        <ProfileButton />
      </div>
    </nav>
  );
};

export default Navbar;

